import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";

const Sidebar = () => {
  const {
    allRecentPosts: { edges: recentPosts },
    // allWpCategory: { nodes: categories },
  } = useStaticQuery(
    graphql`
      query Sidebar {
        allRecentPosts: allWpPost(
          sort: { order: DESC, fields: date }
          limit: 10
        ) {
          edges {
            node {
              id
              title
              uri
            }
          }
        }
        # allWpCategory {
        #   nodes {
        #     id
        #     name
        #     uri
        #   }
        # }
      }
    `
  );

  return (
    <aside>
      <div>
        <h6 className="">Recent Posts</h6>
        <ul className="list-unstyled px-0 recent-post">
          {recentPosts &&
            recentPosts.map(({ node: post }) => {
              return (
                <li key={post.id} className="py-2">
                  <Link className="text-decoration-none py-1" to={post.uri}>
                    {post.title}
                  </Link>
                </li>
              );
            })}
        </ul>
      </div>
    </aside>
  );
};

export default Sidebar;
