import React from "react";
import { graphql } from "gatsby";
import BlogElem from "../components/blog/blog";
import Posts from "../components/blog/posts";
import Seo from "../components/global/seo";

const Blog = ({
  data: {
    allWpPost: posts,
    wpPage: { page },
    wp: {
      themeSettings: { themeSettings },
    },
  },
  pageContext: { totalPages, currentPage, baseUri },
}) => {
  return (
    <>
      <Seo
        title={page.metaTitle + 'Page: ' + currentPage }
        description={page.metaDescription}
        themeSettings={themeSettings}
      />
      <BlogElem themeSettings={themeSettings}>
        <Posts
          posts={posts}
          totalPages={totalPages}
          currentPage={currentPage}
          baseUri={baseUri}
        />
      </BlogElem>
    </>
  );
};

export const pageQuery = graphql`
  query Blog($id: String!, $perPage: Int!, $skipItems: Int!) {
    wpPage(id: { eq: $id }) {
      title
      page {
        metaTitle
        metaDescription
        pageCssOverrides
      }
    }
    wp {
      themeSettings {
        themeSettings {
          siteMetaTitle
          customCssOverrides
          headingSub {
            fieldGroupName
            title
            titleTag
            color
            fontSize
          }
          headingTextStroke {
            fieldGroupName
            title
            titleTag
            color
            fontSize
          }
        }
      }
    }

    allWpPost(
      sort: { order: DESC, fields: date }
      limit: $perPage
      skip: $skipItems
    ) {
      edges {
        node {
          id
          title
          uri
          excerpt
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 760, height: 427)
                }
                extension
                publicURL
              }
            }
          }
        }
      }
    }
  }
`;

export default Blog;
