import React from "react";
import Sidebar from "./sidebar";
import CenteredTitle from "../sections/centered-title";

const BlogElem = ({ children, themeSettings }) => {
  return (
    <>
      <CenteredTitle id="blog-centered-title" data={themeSettings} />
      <section className="py-5 blog-news-section">
        <div className="container">
          <div className="row mt-4">
            <div className="col-lg-9 col-md-9">{children}</div>
            <div className="col-lg-3 col-md-3">
              <Sidebar />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogElem;
