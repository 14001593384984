import React from "react";
import Link from "../utilities/link";
import CmsImage from "../utilities/cms-image";
import Content from "../utilities/content";
import Pagination from "../utilities/pagination";

const Posts = ({ posts, totalPages, currentPage, baseUri }) => {
  return (
    <div className="row">
      {posts.edges &&
        posts.edges.map(({ node: post }, i) => (
          <div
            key={post.id}
            className={i === 0 ? "col-lg-12 col-md-12" : "col-lg-4 col-md-6"}
          >
            {i === 0 ? (
              <div className="row py-5 px-2">
                <div className="col-lg-7 col-md-12">
                  {post.featuredImage && (
                    <Link to={post.uri}>
                      <CmsImage image={post.featuredImage.node} />
                    </Link>
                  )}
                </div>
                <div className="col-lg-5 col-md-12">
                  <h3 className="pt-3">
                    <Link className="text-decoration-none" to={post.uri}>
                      {post.title}
                    </Link>
                  </h3>
                  <Content>{post.excerpt}</Content>
                </div>
              </div>
            ) : (
              <div className="px-2">
                {post.featuredImage && (
                  <div>
                    <Link to={post.uri}>
                      <CmsImage image={post.featuredImage.node} />
                    </Link>
                  </div>
                )}
                <div>
                  <h3 className="pt-3">
                    <Link className="text-decoration-none" to={post.uri}>
                      {post.title}
                    </Link>
                  </h3>
                  <Content>{post.excerpt}</Content>
                </div>
              </div>
            )}
          </div>
        ))}
      <Pagination
        totalPages={totalPages}
        currentPage={currentPage}
        baseUri={baseUri}
      />
    </div>
  );
};

export default Posts;
