import React from "react";
import convertId from "../../utilities/convert-id";
import { graphql } from "gatsby";
import HeadingTag from "../utilities/heading-tag";

const CenteredTitle = ({ data, id }) => {
  return (
    <section id={id ? id : convertId(data.name)} className="section-centered-title">
      <div className="centered-title container d-flex">
        <div className="text-center mx-auto">
          <div className="intro-title">
            {data.headingTextStroke != null && (
              <div className="heading">
                <HeadingTag
                  type={data.headingTextStroke[0].titleTag}
                  className={`textStroke-${data.headingTextStroke[0].color}`}
                  style={
                    data.headingTextStroke != null && {
                      fontSize: `${data.headingTextStroke[0].fontSize}`,
                    }
                  }
                >
                  {data.headingTextStroke[0].title}
                </HeadingTag>
              </div>
            )}
            {data.headingSub != null && (
              <div className="heading-sub">
                <HeadingTag
                  type={data.headingSub[0].titleTag ?? "h2"}
                  className={data.headingSub[0].color}
                >
                  {data.headingSub[0].title}
                </HeadingTag>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment CenteredTitle on WpPage_Sections_Sections_CenteredTitle {
    fieldGroupName
    name
    headingSub {
      fieldGroupName
      title
      titleTag
      color
      fontSize
    }
    headingTextStroke {
      fieldGroupName
      title
      titleTag
      color
      fontSize
    }
  }
`;

export default CenteredTitle;
